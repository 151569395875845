import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import './App.css';
import './MobileGrid.css';
import logo from './logo.jpg';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const App = () => {

  const [showGridItemOverlay, setShowGridItemOverlay] = useState(false);  // Overlay for grid items
  const [showUserOverlay, setShowUserOverlay] = useState(false);         // Overlay for user button
  const [divContent, setDivContent] = useState('');
  const [showAuth, setShowAuth] = useState(false);
  const [isLogin, setIsLogin] = useState(false);//determines that is it a login or a register on the opening menu
  const [email, setEmail] = useState('');//used to signUp or Login
  const [password, setPassword] = useState('');//used to signUp or Login
  const [isLoggedIn, setIsLoggedIn] = useState(false);//not working properly but checks session to know what to show on user button
  const [userData, setUserData] = useState({
    favorites: [],
  });//supabase everything abour the user
  const [data, setData] = useState([]);//All the exercises
  const [visibleData, setVisibleData] = useState([]);
  const [loadCount, setLoadCount] = useState(20);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedBodyPart, setSelectedBodyPart] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
  const [loading, setLoading] = useState(false); // Loading state
  const [weight, setWeight] = useState('');
  const [repetitions, setRepetitions] = useState('');
  const [comment, setComment] = useState('');
  const [date, setDate] = useState(getTodayDate()); // Function to get today's date
  const [clickedExerciseId, setClickedExerciseId] = useState(null); // Store the clicked exercise ID
  const [isBackdropActive, setIsBackdropActive] = useState(false);
  const [mainRecords, setMainRecords] = useState([]); // Records from the Main table
  const [showEditOverlay, setShowEditOverlay] = useState(false); // Overlay for editing a record

  const [showFullOverlay, setShowFullOverlay] = useState(false); // Overlay for full history
  const [fullHistoryData, setFullHistoryData] = useState([]); // Data fetched for full history

  const [editRecord, setEditRecord] = useState({
    weight: 0,
    repetition: 0,
    comment: '',
    date: getTodayDate()
  });

  // Function to get today's date in the format YYYY-MM-DD
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zeros if month or day is less than 10
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }

  const handleEditRecord = (record) => {
    setEditRecord(record);
    setShowEditOverlay(true);
    //setIsBackdropActive(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseEditOverlay = () => {
    setShowEditOverlay(false);
    setEditRecord(null);
    //setIsBackdropActive(false);
    document.body.style.overflow = 'auto';
  };

  const handleUpdateRecord = async () => {
    try {
      setLoading(true);

      const { weight, repetition, comment, date } = editRecord;
      const oldRecord = mainRecords.find(record => record.id === editRecord.id);

      if (
        oldRecord.weight === weight &&
        oldRecord.repetition === repetition &&
        oldRecord.comment === comment &&
        oldRecord.date === date
      ) {
        // If none of the fields have changed, do nothing and return
        return;
      }

      // Update the record in the Main table
      const { data, error } = await supabase
        .from('Main')
        .update({
          weight: editRecord.weight,
          repetition: editRecord.repetition,
          comment: editRecord.comment,
          date: editRecord.date,
        })
        .eq('id', editRecord.id);

      if (error) {
        throw error;
      }

      alert("Success");

      const { data: newMainRecords, error: reloadError } = await supabase
        .from('Main')
        .select('*')
        .eq('user_id', userData.id)
        .eq('exercise_id', clickedExerciseId)
        .order('date', { ascending: false });

      if (reloadError) {
        throw reloadError;
      }

      setMainRecords(newMainRecords || []);
    } catch (error) {
      console.error('Error updating record:', error.message);
    } finally {
      setLoading(false);
      handleCloseEditOverlay();
    }
  };

  const handleDivClick = async (content, exerciseId) => {
    setDivContent(content);
    setClickedExerciseId(exerciseId);
    setShowGridItemOverlay(true);
    setIsBackdropActive(true);
    document.body.style.overflow = 'hidden';

    try {
      const { data, error } = await supabase
        .from('Main')
        .select('*')
        .eq('user_id', userData.id)
        .eq('exercise_id', exerciseId)
        .order('date', { ascending: false });

      if (error) {
        throw error;
      }

      setMainRecords(data || []);
    } catch (error) {
      console.error('Error fetching main records:', error.message);
    }
  };

  const groupedRecords = mainRecords.reduce((acc, record) => {
    const date = record.date.split('T')[0]; // Extract date without time
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(record);
    return acc;
  }, {});

  const handleUserButtonClick = () => {
    if (!showUserOverlay) {
      if (isLoggedIn) {
        setShowUserOverlay(true);
      } else if (!userData.id) {
        setShowAuth(true);
      }
      setIsBackdropActive(true);
      document.body.style.overflow = 'hidden';
      setShowGridItemOverlay(false);
      setShowFullOverlay(false);
    } else {
      setShowUserOverlay(false);
      setIsBackdropActive(false);
      document.body.style.overflow = 'auto';
    }
  };

  const handleLogOut = async () => {
    try {
      setLoading(true);
      let { error } = await supabase.auth.signOut();
      if (!error) {
        setIsLoggedIn(false);
        setUserData({});
      }

    } finally {
      setLoading(false);
      setIsBackdropActive(false);
      document.body.style.overflow = 'auto';
    }
  };

  const handleCloseAuth = () => {
    setShowAuth(false);
    clearFields();
    setShowUserOverlay(false); // Close the user overlay as well
    setIsBackdropActive(false);
    document.body.style.overflow = 'auto';
  };

  const handleAuthTabs = (login) => {
    setIsLogin(login);
    clearFields();
  };

  const handleLoadMore = () => {
    setLoadCount((prevCount) => prevCount + 20);
  };

  const getUniqueValues = (data, key) => {
    return [...new Set(data.map(item => item[key]))];
  };


  useEffect(() => {
    document.title = 'Edzésnapló';
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch exercises data
        let { data: Exercises, error: exercisesError } = await supabase
          .from('Exercises')
          .select('*');

        if (exercisesError) {
          console.error('Error fetching data:', exercisesError);
        } else {
          setData(Exercises);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const checkSession = async () => {
      try {
        setLoading(true);

        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          setIsLoggedIn(true);
          setUserData(user);

          const { data: favoriteExercises, error: favoriteError } = await supabase
            .from('Favorite_exercises')
            .select('exercises')
            .eq('user_id', user.id);

          if (favoriteError) {
            console.error('Error fetching favorite exercises:', favoriteError);
          } else {
            const flattenedFavorites = favoriteExercises.flatMap(item => item.exercises);
            setUserData(prevUserData => ({ ...prevUserData, favorites: flattenedFavorites }));
          }
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    checkSession();
  }, [isLoggedIn]);


  /*useEffect(() => {
    console.log("userdata all:", userData);
  }, [userData]);*/

  useEffect(() => {
    try {
      // Filter and update visible data based on search query, body part, and category
      let updatedData = data.filter(item => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
          (selectedBodyPart ? item.bodypart === selectedBodyPart : true) &&
          (selectedCategory ? item.category === selectedCategory : true);
      });

      // Check if userData exists and has favorites before sorting
      if (userData && userData.favorites) {
        // Sort the data to display favorited exercises first
        updatedData.sort((a, b) => {
          const aIsFavorite = userData.favorites.includes(a.id);
          const bIsFavorite = userData.favorites.includes(b.id);
          if (aIsFavorite && !bIsFavorite) return -1;
          if (!aIsFavorite && bIsFavorite) return 1;
          return 0;
        });
      }

      setVisibleData(updatedData.slice(0, loadCount));

      // Toggle load more button based on the number of loaded exercises
      setShowLoadMoreButton(loadCount < updatedData.length);
    } catch (error) {
      console.error('Error in useEffect:', error);
    }
  }, [data, loadCount, searchQuery, selectedBodyPart, selectedCategory, userData]);

  const handleAuth = async () => {
    try {
      setLoading(true);
      if (!email || !password) {
        alert('Please enter both email and password.');
        return;
      }
      let { user, error } = isLogin
        ? await supabase.auth.signInWithPassword({ email, password })
        : await supabase.auth.signUp({ email, password });

      if (error) throw error;

      setIsLoggedIn(true);
      setUserData(user);
      handleCloseAuth();
    } catch (error) {
      console.error('Error:', error.message);
      alert(`Authentication Error: ${error.message}`);
    } finally {
      setLoading(false); // Reset loading after async method completes
    }
  };

  const handleFavorite = async (exerciseId) => {
    //debugger;
    //console.log("handlefavorite_elsősor", userData.favorites);
    try {
      setLoading(true);
      const isFavorite = userData.favorites.includes(exerciseId);
      let updatedFavorites;
      if (userData.favorites.length == 0) {
        updatedFavorites = [...userData.favorites, exerciseId];
        //setUserData(prevUserData => ({ ...prevUserData, favorites: updatedFavorites }));

        const { error: insertError } = await supabase
          .from('Favorite_exercises')
          .insert([{ user_id: userData.id, exercises: updatedFavorites }]);

        if (insertError) {
          console.error('Error inserting favorite exercises:', insertError);
        } else {
          console.log(`Exercise with ID ${exerciseId} inserted into favorites`);
        }
      }
      else if (userData.favorites.length == 1 && isFavorite) {
        // Delete the exerciseId from Favorite_exercises table
        const { error: deleteError } = await supabase
          .from('Favorite_exercises')
          .delete()
          .eq('user_id', userData.id);

        if (deleteError) {
          console.error('Error deleting favorite exercise:', deleteError);
        } else {
          console.log(`Exercise with ID ${exerciseId} deleted from favorites`);
        }
      }
      else {
        if (isFavorite) {
          updatedFavorites = userData.favorites.filter(id => id !== exerciseId);
          //setUserData(prevUserData => ({ ...prevUserData, favorites: updatedFavorites }));
        }
        else {
          updatedFavorites = [...userData.favorites, exerciseId];
          //setUserData(prevUserData => ({ ...prevUserData, favorites: updatedFavorites }));
        }

        const { error: updateError } = await supabase
          .from('Favorite_exercises')
          .update({ exercises: updatedFavorites })
          .eq('user_id', userData.id);

        if (updateError) {
          console.error('Error updating favorite exercises:', updateError);
        } else {
          console.log(`Exercise with ID ${exerciseId} updated in favorites`);
        }
      }
      if (updatedFavorites == undefined) {
        setUserData(prevUserData => ({ ...prevUserData, favorites: [] }));
      }
      else {
        setUserData(prevUserData => ({ ...prevUserData, favorites: updatedFavorites }));
      }

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Reset loading after async method completes
    }
  };


  const clearFields = () => {
    setEmail('');
    setPassword('');
  };

  const handleCloseOverlay = () => {
    setShowGridItemOverlay(false);
    setShowFullOverlay(false);
    setWeight('');
    setRepetitions('');
    setComment('');
    setClickedExerciseId(null);
    setIsBackdropActive(false);
    setMainRecords([]);
    document.body.style.overflow = 'auto';
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      // Validate weight, repetitions, and date fields
      if (!weight || !repetitions || !date) {
        alert('Please fill out all required fields.');
        return;
      }
      if (!isLoggedIn) {
        alert('Please log in or register to submit.');
        return;
      }
      const { data: mainData, error } = await supabase
        .from('Main')
        .insert([
          {
            user_id: userData.id,
            exercise_id: clickedExerciseId,
            weight: weight,
            repetition: parseInt(repetitions),
            date: date,
            comment: comment || null, // Set default value for comment
          }
        ]);

      if (error) {
        throw error;
      }

      alert("Success");

      const { data: newMainRecords, error: reloadError } = await supabase
        .from('Main')
        .select('*')
        .eq('user_id', userData.id)
        .eq('exercise_id', clickedExerciseId)
        .order('date', { ascending: false });

      if (reloadError) {
        throw reloadError;
      }

      setMainRecords(newMainRecords || []);
    } catch (error) {
      console.error('Error inserting data:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchFullHistory = async () => {
    try {
      setLoading(true);
      const { data: mainData, error: mainError } = await supabase
        .from('Main')
        .select('*')
        .eq('user_id', userData.id)
        .order('date', { ascending: false });

      if (mainError) {
        throw mainError;
      }

      const exerciseIds = mainData.map(entry => entry.exercise_id);
      const { data: exerciseData, error: exerciseError } = await supabase
        .from('Exercises')
        .select('*')
        .in('id', exerciseIds);

      if (exerciseError) {
        throw exerciseError;
      }

      const formattedHistory = mainData.map(mainEntry => {
        const exercise = exerciseData.find(exercise => exercise.id === mainEntry.exercise_id);
        return {
          id: mainEntry.id,
          date: mainEntry.date,
          weight: mainEntry.weight,
          repetition: mainEntry.repetition,
          comment: mainEntry.comment,
          exercise_name: exercise ? exercise.name : 'Unknown Exercise',
        };
      });

      // Group the formatted history by date
      const groupedHistory = formattedHistory.reduce((acc, entry) => {
        const date = entry.date.split('T')[0]; // Extract date without time
        if (!acc[date]) {
          acc[date] = { exercises: {}, records: [] };
        }
        if (!acc[date].exercises[entry.exercise_name]) {
          acc[date].exercises[entry.exercise_name] = [];
        }
        acc[date].exercises[entry.exercise_name].push(entry);
        acc[date].records.push(entry);
        return acc;
      }, {});

      return groupedHistory;
    } catch (error) {
      console.error('Error fetching history:', error.message);
    } finally {
      setLoading(false);
    }
  };


  const handleFetchFullHistory = async () => {
    if (!showFullOverlay) {
      const historyData = await fetchFullHistory();
      setFullHistoryData(historyData);
      setShowFullOverlay(true);
      setIsBackdropActive(true);
      document.body.style.overflow = 'hidden';
    }
  };

  const handleDeleteRecord = async () => {
    try {
      setLoading(true);
      const { error } = await supabase
        .from('Main')
        .delete()
        .eq('id', editRecord.id);

      if (error) {
        throw error;
      }

      alert("Record deleted successfully");

      const { data: newMainRecords, error: reloadError } = await supabase
        .from('Main')
        .select('*')
        .eq('user_id', userData.id)
        .eq('exercise_id', clickedExerciseId)
        .order('date', { ascending: false });

      if (reloadError) {
        throw reloadError;
      }

      setMainRecords(newMainRecords || []);
    } catch (error) {
      console.error('Error deleting record:', error.message);
    } finally {
      setLoading(false);
      handleCloseEditOverlay();
    };
  };

  return (
    <div>
      <Backdrop isActive={isBackdropActive} />
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <button onClick={handleFetchFullHistory}>History</button>
        <button onClick={handleUserButtonClick}>User</button>
      </div>

      <div className="search-and-filter">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>

      <div>
        <select value={selectedBodyPart} onChange={e => setSelectedBodyPart(e.target.value)}>
          <option value="">Select Body Part</option>
          {getUniqueValues(data, 'bodypart').map(part => (
            <option key={part} value={part}>{part}</option>
          ))}
        </select>

        <select value={selectedCategory} onChange={e => setSelectedCategory(e.target.value)}>
          <option value="">Select Category</option>
          {getUniqueValues(data, 'category').map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
      </div>

      <div className="grid-container">
        {visibleData.map((data) => (
          <div key={data.id} className="grid-item" onClick={() => handleDivClick(data.name, data.id)}>
            <p>{data.name}</p>
            {isLoggedIn && ( // Check if the user is logged in
              <button onClick={(e) => { e.stopPropagation(); handleFavorite(data.id); }}>
                {userData && userData.favorites && userData.favorites.includes(data.id) ? '★' : '☆'}
              </button>
            )}
          </div>
        ))}
      </div>

      {showLoadMoreButton && (
        <button id="load_more_button" onClick={handleLoadMore}>Load More</button>
      )}

      {showGridItemOverlay && (
        <div className="fullscreen-overlay">
          <h3>{divContent}</h3>
          <div>
            <label htmlFor="weight">Weight:</label>
            <input type="number" id="weight" step="0.1" min="0" placeholder="Enter weight" value={weight} onChange={(e) => setWeight(e.target.value)} />

            <label htmlFor="repetitions">Repetitions:</label>
            <input type="number" id="repetitions" min="1" placeholder="Enter repetitions" value={repetitions} onChange={(e) => setRepetitions(e.target.value)} />

            <label htmlFor="comment">Comment:</label>
            <input type="text" id="comment" placeholder="Enter comment" value={comment} onChange={(e) => setComment(e.target.value)} />

            <label htmlFor="date">Date:</label>
            <input type="date" id="date" value={date} onChange={(e) => setDate(e.target.value)} />
            <div>
              <button onClick={handleCloseOverlay}>Close</button>
              {isLoggedIn ? (
                <button onClick={() => {
                  let missingFields = [];
                  if (!weight) missingFields.push('Weight');
                  if (!repetitions) missingFields.push('Repetitions');
                  if (!date) missingFields.push('Date');

                  if (missingFields.length > 0) {
                    alert(`Please fill out the following required fields: ${missingFields.join(', ')}.`);
                  } else {
                    handleSubmit();
                  }
                }}>Submit</button>
              ) : (
                <button onClick={() => {
                  handleCloseOverlay();
                  handleAuthTabs(false);
                  setShowAuth(true);
                  setIsBackdropActive(true);
                  document.body.style.overflow = 'hidden';
                }}>Register</button>
              )}
            </div>
            <div className="scrollable-window">
              {Object.entries(groupedRecords).map(([date, records]) => (
                <div key={date}>
                  <h4>{date}</h4>
                  {records
                    .sort((a, b) => a.id - b.id)
                    .map((record, index) => (
                      <div className="record-row" key={index} onClick={() => handleEditRecord(record)}>
                        <p>Weight: {record.weight} Repetitions: {record.repetition}</p>
                        {record.comment && <p>Comment: {record.comment}</p>}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {showFullOverlay && (
        <div className="fullscreen-overlay">
          <h3>History</h3>
          <div>
            <div>
              <button onClick={handleCloseOverlay}>Close</button>
            </div>
            <div className="scrollable-window">
              {Object.entries(fullHistoryData).map(([date, data]) => (
                <div key={date}>
                  <h4>{date}</h4>
                  {Object.entries(data.exercises).map(([exerciseName, recordsForExercise]) => (
                    <div key={exerciseName}>
                      <h5>{exerciseName}</h5>
                      {recordsForExercise.length > 0 ? (
                        recordsForExercise
                          .sort((a, b) => a.id - b.id)
                          .map((record, index) => (
                            <div className="record-row" key={index} onClick={() => handleEditRecord(record)}>
                              <p>Weight: {record.weight} Repetitions: {record.repetition}</p>
                              {record.comment && <p>Comment: {record.comment}</p>}
                            </div>
                          ))
                      ) : (
                        <p>No history</p>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {showEditOverlay && (
        <div className="fullscreen-overlay">
          <h3>Edit Record</h3>
          <div>
            <label htmlFor="edit-weight">Weight:</label>
            <input type="number" id="edit-weight" value={editRecord.weight} onChange={(e) => setEditRecord({ ...editRecord, weight: e.target.value })} />

            <label htmlFor="edit-repetitions">Repetitions:</label>
            <input type="number" id="edit-repetitions" value={editRecord.repetition} onChange={(e) => setEditRecord({ ...editRecord, repetition: e.target.value })} />

            <label htmlFor="edit-comment">Comment:</label>
            <input type="text" id="edit-comment" value={editRecord.comment} onChange={(e) => setEditRecord({ ...editRecord, comment: e.target.value })} />

            <label htmlFor="edit-date">Date:</label>
            <input type="date" id="edit-date" value={editRecord.date} onChange={(e) => setEditRecord({ ...editRecord, date: e.target.value })} />

            <div>
              <button onClick={handleCloseEditOverlay}>Cancel</button>
              <button onClick={handleUpdateRecord}>Update</button>
              <button onClick={() => {
                const confirmDelete = window.confirm('Are you sure you want to delete this record?');
                if (confirmDelete) {
                  handleDeleteRecord();
                }
              }}>Delete</button>
            </div>
          </div>
        </div>
      )}

      {showUserOverlay && isLoggedIn && (
        <div className="fullscreen-overlay">
          <div><button onClick={handleCloseAuth}>Close</button></div>
          <div><button>Change Password</button></div>
          <div><button onClick={handleLogOut}>Logout</button></div>
        </div>
      )}

      {showAuth && (
        <div className="fullscreen-overlay">
          <button onClick={handleCloseAuth}>Close</button>
          <div>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
            <button onClick={handleAuth}>{isLogin ? 'Login' : 'Register'}</button>
          </div>
          <div>
            <button onClick={() => handleAuthTabs(false)}>Register</button>
            <button onClick={() => handleAuthTabs(true)}>Login</button>
          </div>
        </div>
      )}

      <div className="footer">
        <p>Footer</p>
      </div>
      {/* Loading screen */}
      {loading && (
        <div className="loading-screen">
          <div>Loading...</div>
        </div>
      )}
    </div>
  );
};

const Backdrop = ({ isActive }) => {
  return <div className={isActive ? 'backdrop active' : 'backdrop'}></div>;
};

export default App;